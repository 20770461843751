import { FormControl, FormGroup } from '@angular/forms';
import { AllFacilityListItem } from '../modules/facilities/facilities.service';

export interface LongLat {
  longitude: number;
  latitude: number;
}

export interface XYPoint {
  x: number;
  y: number;
}

export interface NullableXYPoint extends XYPoint {
  valid: boolean;
}

export type SalesTrailerType =
  | 'reefer'
  | 'dry_van'
  | 'power_only'
  | 'flatbed'
  | 'hopper'
  | 'pneumatic'
  | 'belly_dump'
  | 'end_dump'
  | 'straight_box'
  | 'intermodal_chassis'
  | 'tanker'
  | 'stepdeck'
  | 'low_boy'
  | 'conestoga'
  | '40_foot_high_cube_container'
  | '20_foot'
  | '40_foot';

export interface MappableStop {
  sequence?: number | string;
  lngLat: XYPoint;
  color?: string;
}

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any> ? FormGroup<ControlsOf<T[K]>> : FormControl<T[K]>;
};

export interface BackhaulAutoPopulate {
  backhaulRecordId: number;
  originFacilityId: string;
  destinationFacilityId: string;
  stopType: 'pickup' | 'dropoff';
  rateCents: number;
  trailerType: string;
  selectedTime: string;
}

export enum LAMStatus {
  readyForUse = 'ready_for_use',
  maintenanceNeeded = 'maintenance_needed',
  inspectionNeeded = 'inspection_needed',
  notAvailable = 'not_available',
  inCustody = 'in_custody',
}

export enum YardManagerTrailerStatus {
  loaded = 'loaded',
  loadedInbound = 'loaded_inbound',
  loadedOutbound = 'loaded_outbound',
  empty = 'empty',
  unknown = 'unknown',
  maintenanceNeeded = 'maintenance_needed',
  atDock = 'at_dock',
  reserved = 'not_available',
}

export enum TrailerStatusUpdatedMessage {
  loaded = 'Trailer marked loaded',
}

export interface TrailerDashboardItem {
  assetId: string;
  assetName: string;
  facilityName: string;
  driverName: string;
  assetStatus: LAMStatus;
  assetStatusUpdatedAt: string;
  assetStatusUpdatedBy: string;
  loadedStatus: TrailerLoadedStatus;
  loadedStatusUpdatedAt: string;
  loadedStatusUpdatedBy: string;
  lngLat: NullableXYPoint;
  stateCode: string;
  inboundOutbound: 'inbound' | 'outbound' | null;
  status: YardManagerTrailerStatus;
}

export enum TrailerLoadedStatus {
  loaded = 'loaded',
  empty = 'empty',
  unknown = 'unknown',
}

export interface ColtCheckIn {
  isProcessing: boolean;
  inColt: boolean;
  isReturn: boolean;
  loadId: string;
  referenceNumber: string;
  bolUrl: string;
}

export interface TrailerYardItem {
  facilityId: string;
  assetId: string;
  assetName: string;
  externalIdentifier: string;
  status: YardManagerTrailerStatus;
  assetStatus: TrailerLoadedStatus;
  sensorLoadedStatus: TrailerLoadedStatus | null;
  sensorConfidence: number | null;
  inboundOutbound: 'inbound' | 'outbound' | null;
  lastLocation: NullableXYPoint;
  lastLocationUpdateAt: string | null;
  payloadId: string | null;
  payloadName: string | null;
  payloadQuantity: number | null;
  payloadUnit: string | null;
  updatedAt: string | null;
  createdAt: string;
  notes: string | null;
  lamAssetStatus: string;
  loadId: string | null;
  customerLoadId: string | null;
  recentLoad: YardManagerRecentLoad;
  maintenanceNeeded?: MaintenanceNeeded;
  facilityName: string | null;
  coltCheckinInfo: ColtCheckIn | null;
}

export interface YardManagerProduct {
  id: string;
  name: string;
  unitName: string;
  unitAbbreviation: string;
}

export interface AvailableLoadForYM {
  loadId: string;
  customerLoadId: string | null;
  arrivalTime: string;
  pickupEndsTime: string | null;
  dropoffEndsTime: string | null;
  alreadyAssigned: boolean;
  assignedToName: string | null;
  assignedToExternalIdentifier: string | null;
  payload: string | null;
  payloadQuantity: number | null;
}

export interface YardManagerRecentLoad {
  loadId: string;
  referenceNumber: string;
  driverName: string;
  completedAt: string | null;
  carrierName: string;
  stops: YardManagerRecentLoadStop[];
  coltUploads: string[];
  coltBypassed: boolean;
  coltBypassedAt: string | null;
  coltBypassReason: string | null;
  coltBypassedBy: string | null;
  bolUrl: string;
}

export interface YardManagerRecentLoadStop {
  facilityName: string;
  arrivedAt: string | null;
  completedAt: string | null;
  facilityTimezone: string;
}

export interface FacilityNameAndID {
  facilityId: string;
  facilityName: string;
}

export interface ShipmentFacility extends FacilityNameAndID {
  customerReferenceNumber: string | null;
  timezone: string;
  datesToConfirm: ShipmentDatesConfirmed[];
  chepFacilityType: 'tpm' | 'service_center' | null;
}

export interface ShipmentDatesConfirmed {
  date: string;
  loadsLeft: number;
  loadsCompleted: number | null;
  averageDwellTimeMinutes: number | null;
  averageDwellTimeMinutesOnTime: number | null;
  averageDwellTimeMinutesEarlyLate: number | null;
  appointments: Shipment[];
}

export interface FacilityListItem extends ShipmentFacility {
  canManageFacility: boolean;
  inventoryTargetType: string;
  inventoryMaxCount: number;
  inventoryTargetCount: number;
  trailersMatching: number;
  lastUpdatedAt: string | null;
  lastUpdatedByName: string | null;
  alarm: boolean;
  etaEmailEnabled: boolean;
  yardEmailEnabled: boolean;
  isFacilityContact: boolean;
  canPredictInventory: boolean;
  isPriority: boolean;
  poolId: number;
  poolName: string;
  poolScac: string;
  dShrutDetails?: FacilityTargetData | null;
  isServiceCenter: boolean;
  isEmptyPlanningEnabled: boolean;
  autoOrderType: AutoOrderType;
}

export enum FacilityPriorityType {
  forever = 'forever',
  timed = 'timed',
  target = 'target',
}

export enum FacilityPriorityTargetType {
  loaded = 'loaded',
  empty = 'empty',
  any = 'any',
}

export interface FacilityTrailer {
  facilityId: string;
  assetId: string;
  assetName: string;
  loadedStatus: TrailerLoadedStatus;
  maintenanceNeeded: boolean;
  inboundOutbound: 'inbound' | 'outbound' | null;
}

export enum FacilityTargetType {
  loaded = 'loaded',
  empty = 'empty',
  loadedAndEmpty = 'loaded_and_empty',
}

export interface FacilityTargetData {
  id: string;
  name: string;
  customerName: string;
  customerReferenceNumber: string;
  trailerTargetCount: number;
  trailerMaxCount: number;
  trailerTargetType?: FacilityTargetType;
  trailers: FacilityTrailer[] | null;
  loadedCount: number;
  emptyCount: number;
  reqLoadsNeeded: number;
  neededTenderCount: number;
  notAssignedLoadCount: number;
  pendingLoadCount: number;
  inProgressLoadCount: number;
  criticalStatus: {
    isCritical: boolean;
    hoursToCritical: number;
    warningLevel: 'low' | 'high';
    pessimisticHoursToCritical: number | null;
    depletionTrailersPerDay: number | null;
  } | null;
  priority?: {
    priorityType: FacilityPriorityType;
    targetType: FacilityPriorityTargetType;
    timedEndsAt: string;
  };
}

export interface AppointmentsToRespondTo {
  facilities: AppointmentFacility[];
}

export interface AppointmentFacility {
  facilityId: string;
  facilityName: string;
  timezone: string;
  datesToConfirm: AppointmentDateToConfirm[];
}

export interface AppointmentDateToConfirm {
  date: string;
  appointments: AppointmentDetail[];
}

export interface AppointmentDetailSuggestTime {
  suggestionId: number;
  appointmentTime: string;
  isBestTime: boolean;
}

export interface AppointmentDetail {
  id: string;
  loadId: string;
  referenceNumber: string | null;
  suggestedTimes: AppointmentDetailSuggestTime[];
  hasResponded: boolean;
  requestedAt: string;
  respondedAt: string | null;
  respondedByName: string | null;
  appointmentSetTime: string | null;
  appointmentExtremaStart: string | null;
  appointmentExtremaEnd: string | null;
  hasUnresovledProposedTime: boolean;
  dockNumber: string | null;
  trailerType: string;
  loadType: 'Live Load' | 'Drop Load';
  quantity: number;
  commodity: string;
  unitAbbreviation: string;
  stopType: string;
  ediPayload: string;
  trailerId: number;
  trailerName: string;
}

export interface Shipment extends AppointmentDetail {
  isTrackingLate: number;
  timeWhenDiscountEnds: string;
  carrierScac: string;
  hasArrived: boolean;
  completedAt: string;
  arrivedAt: string;
  eta: string | null;
  isPriority: boolean;
  hasEmptyAssetPickup: boolean;
  shouldPreload: boolean;
}

export interface MyShipments {
  facilities: ShipmentFacility[];
}

export enum StopType {
  pickup = 'pickup',
  dropoff = 'dropoff',
  emptyAssetPickup = 'empty_asset_pickup',
  emptyAssetDropoff = 'empty_asset_dropoff',
  loadedAssetStorageDropoff = 'loaded_asset_storage_dropoff',
  loadedAssetStoragePickup = 'loaded_asset_storage_pickup',
}

export enum LoadingType {
  live = 'live',
  drop = 'drop',
}

export enum YardManagerStatus {
  atDock = 'at_dock',
  inYard = 'in_yard',
  unknown = 'unknown',
}

export interface FacilityCompletedLoad {
  id: string;
  referenceNumber: string | null;
  completedAt: string | null;
  type: StopType;
  arrivedAt: string | null;
  completedAtStop: string | null;
  trailerId: number;
  trailerName: string;
  ediPayload: string;
  commodity: string;
  quantity: string;
  loadType: string;
}

export interface FacilitySchedulingUser {
  id: string;
  name: string;
  email: string;
  shouldSendBookingEmails: boolean;
}

export interface FacilityUsers {
  users: FacilitySchedulingUser[];
  excludedFromETAEmail: string[];
  excludedFromYardEmail: string[];
}

export interface FacilityNotificationPreferences {
  yardEmails: boolean;
  etaEmails: boolean;
}

export interface CreateLoadRecommendation {
  recommendationId: number;
  trailerType: SalesTrailerType;
  poolId: number;
  poolName: string;
  poolScac: string;
  facilityId: string;
  facilityName: string;
  facilityAutoOrderType: AutoOrderType;
  stops: CreateLoadRecommendationStop[];
}

export interface CreateLoadRecommendationFlat {
  recommendationId: number;
  trailerType: SalesTrailerType;
  poolId: number;
  poolName: string;
  poolScac: string;
  facilityId: string;
  facilityName: string;
  facilityAutoOrderType: AutoOrderType;
  pickupStop: CreateLoadRecommendationStop;
  dropoffStop: CreateLoadRecommendationStop;
}

export interface CreateLoadRecommendationStop {
  sequence: number;
  facilityId: string;
  facilityName: string;
  stopType: StopType;
  loadingType: LoadingType;
  extremaWindowStartTime: string;
  extremaWindowEndTime: string;
  facilityTimezone: string;
}

export interface CancelLoadRecommendation {
  loadId: string;
  loadReference: string | null;
  facilityId: string;
  facilityName: string;
  facilityAutoOrderType: AutoOrderType;
  poolId: number;
  poolName: string;
  poolScac: string;
  stops: CancelLoadRecommendationStop[];
}

export interface CancelLoadRecommendationFlat {
  loadId: string;
  loadReference: string | null;
  facilityId: string;
  facilityName: string;
  facilityAutoOrderType: AutoOrderType;
  poolId: number;
  poolName: string;
  poolScac: string;
  pickupStop: CancelLoadRecommendationStop;
  dropoffStop: CancelLoadRecommendationStop;
}

export interface CancelLoadRecommendationStop {
  loadId: string;
  facilityId: string;
  facilityName: string;
  facilityTimezone: string;
  arrivalWindowStartsAt: string;
  arrivalWindowEndsAt: string;
}

export interface RescheduleLoadRecommendation {
  loadId: string;
  loadReferenceId: string | null;
  poolId: number;
  poolName: string;
  poolScac: string;
  stops: RescheduleLoadRecommendationStop[];
}

export interface RescheduleLoadRecommendationStop {
  facilityId: string;
  facilityName: string;
  facilityAutoOrderType: AutoOrderType;
  stopType: StopType;
  loadingType: LoadingType;
  extremaWindowStartTime: string;
  extremaWindowEndTime: string;
  facilityTimezone: string;
}

export interface InventoryPrediction {
  trailerCounts: InventoryDataPoint[];
  itemCounts: InventoryDataPoint[];
  emptyCounts: InventoryDataPoint[];
  daysOfInventory: InventoryDataPoint[];
  shipments: InventoryShipment[];
  canPredictionInventory: boolean;
  daysOfInventoryTarget: number;
  daysOfInventoryCritical: number;
  trailerInventoryCritical: number;
  trailerTarget: number;
  type: 'empty' | 'loaded';
}

export interface InventoryDataPoint {
  count: number;
  time: string;
}

export interface InventoryShipment {
  loadId: string | null;
  arrivalTime: string;
  isEnroute: boolean;
}

export interface InboundEmpty {
  assetId: number;
  name: string;
}

export interface EarlyArrivalSettings {
  earlyArrivalEnabled: boolean;
  earlyArrivalExpiresAt: string | null;
  earlyArrivalDefaultValue: boolean;
  setByUserId: string | null;
  setByUserName: string | null;
}

export interface AppointmentResponse {
  acceptedSuggestionId?: number;
  proposedAlternativeTime?: string;
  dockNumber?: string;
}

export interface LoadDetails {
  payloadName: string | null;
  carrierSCAC: string;
  subscribed: boolean;
  customerRateCents: number;
  id: string;
  status: LohiLoadStatus;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  companyName: string;
  companyMCNumber: string;
  companyDOTNumber: string;
  driverId: string;
  driverName: string;
  driverPhone: string;
  driverLastWaypointUpdateAt: string;
  driverLastWaypointLngLat: NullableXYPoint;
  shipper: string;
  shipperId: string;
  broker: any;
  rateCents: number;
  rateCentsPerMile: number;
  weightPounds: number;
  tempFahrenheit: any;
  details: string;
  shippingComments: any;
  stops: Stop[];
  files: File[];
  trailerTypes: TrailerType[];
  tripMileage: number;
  deadheadMiles: number;
  durationMinutes: number;
  bolNumber: string | null;
  actualQuantity: number | null;
  completedAt: string;
  adjustedCompletedAt: string;
  referenceNumber: string;
  brokerNotificationMinutes: number;
  trailerDisplayName: string;
  possibleTrailerTypes: TrailerType[];
  acknowledgedAt: string;
  acknowledgedBy: string;
  acknowledgedByName: string;
  creatorCustomerName: any;
  creatorCustomerEmail: any;
  creatorCustomerPhone: any;
  creatorCustomerType: any;
  createdByUserName: string;
  hasCarrierAssignmentRequest: boolean;
  isAwaitingRateConSignature: boolean;
  customerId: string;
  customerPlatformFeeRate: number;
  customerMarginTakeRate: number;
  loadBillingApprovedAt: string;
  isPaid: boolean;
  isCPG: boolean;
  isDrayage: boolean;
  isMatchback: boolean;
  isAutoDispatchable: boolean;
  isAutoDispatchOnly: boolean;
  dispatcherName: string;
  requestRateLoad: boolean;
  customerBrokerSubsidiaryId: string;
  poolId: number;
  vpfAutodispatchLocked: boolean;
  allowVPFAutodispatchLock: boolean;
  lamTrailer?: {
    id: number;
    name: string;
  };
  isPriority: boolean;
  readyStatus: {
    isReady: boolean;
    markedReadyForPickupAt: string;
    readyNote: string;
    maintTicketDescription: string;
  } | null;
  customerRating: number | null;
  customerDescription: string | null;
}

export interface Stop {
  id: number;
  loadId: string;
  type: StopType;
  loadingType: LoadingType;
  sequence: number;
  lngLat: XYPoint;
  title: string;
  address: string;
  city: string;
  state: string;
  stateCode: string;
  zipCode: string;
  arrivalWindowStartsAtExtrema: string;
  arrivalWindowStartsAt: string;
  arrivalWindowMinutes: number;
  arrivalWindowEndsAtExtrema: string;
  originalArrivalWindowStartsAt?: string;
  details: string;
  commodity: string;
  quantity: number;
  unit: Unit;
  completedAt: string;
  referenceNumber: string;
  detentionFreeMinutes: number;
  appointmentTrackingNumber: string;
  arrivedAt: string;
  exitedAt?: string;
  eta: string;
  etaUpdatedAt: string;
  facilityId: string;
  facilityName: string;
  facilityFCFS: boolean;
  facilityApptRequired: boolean;
  facilityCustomerReferenceNumber: string;
  facilityOwnerId: string;
  timeZone: TimeZone;
  isFCFS: boolean;
  siteRadiusMiles: number;
  facilityExternalId: string;
  attachEmptyTrailerPickup: boolean;
}

export interface Unit {
  id: number;
  name: string;
  abbreviation: string;
}

export interface TimeZone {
  name: string;
  abbreviation: string;
}

export interface File {
  id: number;
  name: string;
  destination: string;
  url: string;
  category: string;
  uploadedAt: string;
  uploadedBy: string;
  archivedAt: any;
  archivedBy: any;
  passedQa: any;
  qaAt: any;
  qaBy: any;
  qaRejectedReason: any;
  displayName: string;
}

export interface TrailerType {
  id: string;
  name: string;
}

export interface GlobalSearchResult {
  id: string;
  referenceNumber: string;
  assetName: string;
  status: LohiLoadStatus;
}

export enum LohiLoadStatus {
  pending = 'pending',
  inProgress = 'in_progress',
  completed = 'completed',
  cancelled = 'cancelled',
  notAssigned = 'not_assigned',
  atYard = 'at_yard',
  assetAvailable = 'asset_available',
}

export interface MaintenanceNeeded {
  assetId: string;
  description: string;
  damagedTires: Tire[];
  damagedInAccident: boolean;
  damagedByDriver: boolean;
  damagedByYardOperator: boolean;
  files: AssetFile[];
}

export interface AssetFile {
  id: number;
  url: string;
  name?: string;
}

export interface Tire {
  position: string;
  positionDescription: string;
  damaged: boolean;
  size: string;
}

export interface MaintenanceNeededCreationDTO {
  assetId: string;
  assetName: string;
  facilityId: string;
  description: string;
  isLoaded: boolean;
  loadedForDirection?: 'inbound' | 'outbound';
  loadedForLoadId?: string;
  damagedTires: Tire[];
  damagedInAccident: boolean;
  damagedByDriver: boolean;
  damagedByYardOperator: boolean;
  files: AssetFile[];
}

export interface PoolInfo {
  name: string;
  stats: PoolInfoStats[];
}

export enum Period {
  Yesterday = -1,
  Today = 0,
  Tomorrow = 1,
}

export interface PoolInfoStats {
  period: Period;
  periodDescription: string;
  numberOfDrivers: number;
  numberOfDriversCustomer: number;
  numberOfShipmentsCustomer: number;
  numberOfShipments: number;
  averageTurnRate: number;
  maximumTurnRate: number;
}

export interface InventoryHistory {
  trailerCounts: InventoryDataPoint[];
  daysOfInventory: InventoryDataPoint[];
  emptyCounts: InventoryDataPoint[];
}

export interface FacilityNote {
  id: number;
  creatorName: string;
  note: string;
  createdAt: string;
}

export interface SCPriorityLoad {
  loadId: string;
  referenceNumber: string;
  deliveryFacilityName: string;
  eta: string;
}

export interface DwellTime {
  startsAt: string;
  dwellTimeMinutes: number;
}

export interface LoadedAndUnloadCountByDay {
  date: string;
  loadedCount: number;
  emptyCount: number;
}

export interface DriverDwelling {
  loadId: string;
  referenceNumber: string;
  driverName: string;
  arrivedAt: string;
  dwellMinutes: number;
}

export interface TrailerRecentLoad {
  loadId: string;
  referenceNumber: string;
  completedAt: string;
  fileUrl: string;
  isEad: boolean;
}

export interface CompletedLoadPageQuery {
  page: number;
  pageSize: number;
  searchTerm?: string;
  ioFilter?: 'inbound' | 'outbound';
}

export interface AllFacilitiesPageQuery {
  page: number;
  pageSize: number;
  search?: string;
  poolId?: number[];
  targetType?: string[];
  autoOrderType?: AutoOrderType[];
}

export interface UpcomingLoadDay {
  type: string;
  stopType: StopType;
  date: string;
  count: number;
}

export interface EmptyTrailerPlanning {
  deliveredEmptiesCount: number;
  deliveredTarget: number;
  producedEmptiesCount: number;
  producedTarget: number;
  emptiesTarget: EmptyTrailerPlanningTarget | null;
}

export interface EmptyTrailerPlanningTarget {
  trailersOffloadRate: number;
  trailersLoadRate: number;
}

export interface EmptyPlanningHistory {
  dateStr: string;
  producedEmptiesCount: number;
  producedEmptiesTarget: number;
}

export interface PreloadHistory {
  dateStr: string;
  totalLoads: number;
  preloadedLoads: number;
}

export interface FacilityPageInfo {
  facilities: AllFacilityListItem[];
  totalFacilities: number;
}

export enum AutoOrderType {
  none = 'none',
  full = 'full',
  partial = 'partial',
}

export interface AutoOrderInputPayload {
  CurrentTime: string;
  SimulateToTime: string;
  Facilities: AutoOrderFacility[];
  PendingLoods: any[];
  Timezone: string;
  FocalFacilityID: string;
  Params: {
    MaxTimeForLoadCreation: string;
    MinTimeForLoadCreation: string;
  };
}

export enum AutoOrderFacilityType {
  retailer = 'retailer',
  manufacturer = 'manufacturer',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  serviceCenter = 'service_center',
  tpm = 'tpm',
}

export const autoOrderFacilityTypes = Object.values(AutoOrderFacilityType);

export enum AutoOrderFacilityStatusType {
  stockOut = 'stockOut',
  critical = 'critical',
  overTarget = 'overTarget',
  needsDelivery = 'needsDelivery',
  overPool = 'overPool',
  underPool = 'underPool',
  // facilities only
  overYardMax = 'overYardMax',
  ok = 'ok',
}

export const autoOrderFacilityStatusTypes = Object.values(AutoOrderFacilityStatusType);

export interface ServiceCenterPlanning {
  demandByFacility: Record<string, number>;
  collectionsByFacility: Record<string, number>;
  issuesByFacility: Record<string, number>;
  mileageBrackets: {
    lowerMileage: number;
    cents: number;
  }[];
}

export interface AutoOrderDashboard {
  simulationId: number;
  poolId: number;
  inputPayload: AutoOrderInputPayload;
  outputPayload: AutoOrderOutputPayload;
  createdAt: string;
  facilityNames: Record<string, string>;
  facilityTypes: Record<string, AutoOrderFacilityType>;
  facilityRefNum: Record<string, string>;
  importantFacilities: Record<string, boolean>;
  otpRisks: Record<string, boolean>;
  serviceCenterOutputs: Record<string, ServiceCenterOutput>;
  serviceCenterPlanning: ServiceCenterPlanning;
}

export interface ServiceCenterOutput {
  producedEmpties: number;
  targetEmpties: number;
}

export interface AutoOrderFacility {
  ID: string;
  Location: [number, number];
  Timezone: string;
  CustomerID: string;
  Inventories: TrailerInventory[];
  ShippingHours: OperationalHours[];
  LoadingHours: OperationalHours[];
  TrailerCountsByType: Record<string, TrailerCounts>;
  IsSourceFacility: boolean;
  SourceFacilityIDs: string[];
  DoNotAutoOrder: boolean;
  TotalTrailerCapacity: number;
}

export interface TrailerInventory {
  ID: number;
  TrailerType: string;
  ConsumptionType: 'consumeFulls' | 'consumeEmpties';
  ConsumptionSchedule?: ConsumptionSchedule;
  MaxTrailerCapacity: number;
  TargetForTrailerType: number;
}

export interface ConsumptionSchedule {
  DefaultEmptyConsumptionRatePerHour: number;
  DefaultLoadedConsumptionRatePerHour: number;
}

export interface OperationalHours {
  OpenMinuteOfDayPeriodsByWeekday: number[][][];
}

export enum AutoOrderPoolStatus {
  overPool = 'overPool',
  underPool = 'underPool',
  atPool = 'atPool',
  noTargets = 'noTargets',
}

export interface TrailerCounts {
  LoadedOutbound: number;
  LoadedInbound: number;
  Empty: number;
  PoolStatus: AutoOrderPoolStatus;
  PoolSize: number;
}

export interface AutoOrderOutputPayload {
  InitialSimulationReport: SimulationReport;
  FinalSimulationReport: SimulationReport;
  NewLoadRecommendations: NewLoadRecommendation[];
  LoadDemandRecommendations: LoadDemandRecommendation[];
  LoadRescheduleRecommendations: any[];
  LoadCancelRecommendations: any;
  TrailerProjectionsByInventoryID: any;
}

export interface LoadDemandRecommendation {
  FacilityID: string;
  TrailerType: string;
  LoadingType: string;
  Type: string;
  ArrivalWindowExtremaStartTime: string;
  ArrivalWindowExtremaEndTime: string;
}

export interface RecommendationLoadBase {
  TrailerType: string;
  Stops: RecommendationLoadBaseStop[];
}

export interface RecommendationLoadBaseStop {
  ID: number;
  FacilityID: string;
  LoadingType: string;
  ArrivalWindowStartTime: string;
  ArrivalWindowEndTime: string;
  ArrivalWindowExtremaStartTime: string;
  ArrivalWindowExtremaEndTime: string;
}

export interface NewLoadRecommendation extends RecommendationLoadBase {
  FocalFacilityID: string;
}

export interface SimulationReport {
  RealLoadCompletions: any;
  LoadDemandRealizations: any;
  FacilityTimeSeries: FacilitySnapshot[];
  NptStatsByInventory: Record<number, NptStatsByInventory>;
  ExcessStatsByInventory: Record<number, ExcessStatsV2>;
}

export interface FacilitySnapshot {
  Timestamp: string;
  StateByFacilityID: Record<string, FacilityState>;
}

export interface FacilityState {
  FacilityID: string;
  AboveCapacity: boolean;
  Status: AutoOrderFacilityStatusType;
  TrailerCountsByType: Record<string, TrailerCounts>;
  StateByInventoryID: Record<number, FacilityInventoryState>;
}

export interface FacilityInventoryState {
  InventoryID: number;
  FacilityID: string;
  TrailerType: string;
  TargetType: 'empty' | 'loaded' | 'none';
  BelowTarget: boolean;
  Status: AutoOrderFacilityStatusType;
  StandingLoadsAndDemandsByType: Record<string, number>;
  EmptyDemand: number;
  OutboundDemand: number;
}

export interface NptStatsByInventory {
  TimeFirstBelowTarget: string;
  TotalTrailerHoursBelowTarget;
}

export interface ExcessStatsV2 {
  TimeFirstAboveMax: string;
  TotalTrailerHoursAboveMax: number;
}

export interface IUsableFacility {
  id: string;
  name: string;
  refNum: string;
  type: AutoOrderFacilityType;
  state: FacilityState;
  status: {
    isServiceCenter: boolean;
    status: AutoOrderFacilityStatusType;
    level: 'ok' | 'warning' | 'critical';
    message: string;
  };
  serviceCenterOutput?: ServiceCenterOutput;
  inputInfo: AutoOrderFacility;
  important: boolean;
}

export interface StateAndInventory {
  state: FacilityInventoryState;
  inventory: TrailerInventory;
}

export interface StateAndInventoryWithStatus extends StateAndInventory {
  status?: {
    status: AutoOrderFacilityStatusType;
    level: 'ok' | 'warning' | 'critical';
    message: string;
  };
}

export class UsableInventory {
  constructor(
    public readonly trailerType: string,
    public readonly states: StateAndInventory[],
    public readonly trailerCounts: TrailerCounts,
    public readonly facility: UsableFacility,
  ) {}

  public totalTrailersInInventory() {
    return this.trailerCounts.Empty + this.trailerCounts.LoadedInbound + this.trailerCounts.LoadedOutbound;
  }

  public getTargetBasedOnState(state: StateAndInventory) {
    return state.inventory.ConsumptionType === 'consumeFulls' ? this.getLoadedTarget() : this.getEmptyTarget();
  }

  public getCountBasedOnState(state: StateAndInventory) {
    return state.inventory.ConsumptionType === 'consumeFulls'
      ? this.getLoadedInboundTrailers()
      : this.getEmptyTrailers();
  }

  public getLoadedTarget() {
    const loadedInv = this.states.find((value) => value.inventory.ConsumptionType === 'consumeFulls');
    if (!loadedInv) {
      return 0;
    }

    return loadedInv.inventory.TargetForTrailerType;
  }

  public getEmptyTarget() {
    const emptyInv = this.states.find((value) => value.inventory.ConsumptionType === 'consumeEmpties');
    if (!emptyInv) {
      return 0;
    }

    return emptyInv.inventory.TargetForTrailerType;
  }

  public getLoadedInboundTrailers() {
    if (this.getLoadedTarget() > 0) {
      return this.trailerCounts.LoadedInbound;
    }
    return this.trailerCounts.LoadedInbound;
  }

  public getLoadedOutboundTrailers() {
    if (this.getLoadedTarget() === 0) {
      return this.trailerCounts.LoadedOutbound;
    }
    return this.trailerCounts.LoadedOutbound;
  }

  public getEmptyTrailers() {
    return this.trailerCounts.Empty;
  }

  public getTotalTargetCount() {
    return this.states.reduce(
      (previousValue, currentValue) => previousValue + currentValue.inventory.TargetForTrailerType,
      0,
    );
  }

  public getInventoryStatus = (): {
    status: AutoOrderFacilityStatusType;
    level: 'ok' | 'warning' | 'critical';
    message: string;
  } => {
    const statuses = this.getInventoryStatuses();
    const critStatus = statuses.find((value) => value.level === 'critical');
    if (critStatus) {
      return critStatus;
    }
    const warningStatus = statuses.find((value) => value.level === 'warning');
    if (warningStatus) {
      return warningStatus;
    }
    return statuses.find((value) => value.level === 'ok');
  };

  private getInventoryStatuses(): {
    status: AutoOrderFacilityStatusType;
    level: 'ok' | 'warning' | 'critical';
    message: string;
  }[] {
    const statuses = [];
    for (const facilityInventoryState of this.states) {
      statuses.push(this.getInventoryStateStatus(facilityInventoryState));
    }

    return statuses;
  }

  public getInventoryStatesWithEmpties() {
    return this.states.map((value) => {
      return {
        ...value,
        status: this.getInventoryStateStatus(value),
      } as StateAndInventoryWithStatus;
    });
  }

  public getInventoryStateStatus(inv: StateAndInventory) {
    if (
      inv.state.Status === AutoOrderFacilityStatusType.stockOut &&
      (this.getPoolCount() > 1 || this.facility.otpRisk)
    ) {
      return {
        status: AutoOrderFacilityStatusType.stockOut,
        level: 'critical',
        message: `${inv.inventory.ConsumptionType === 'consumeFulls' ? 'Stock Out' : 'No Empties'}`,
      };
    } else if (
      inv.state.Status === AutoOrderFacilityStatusType.critical &&
      (this.getPoolCount() > 1 || this.facility.otpRisk)
    ) {
      return {
        status: AutoOrderFacilityStatusType.critical,
        level: 'critical',
        message: `${inv.inventory.ConsumptionType === 'consumeFulls' ? 'Critical Inventory' : 'Critical Empties'}`,
      };
    } else if (inv.state.Status === AutoOrderFacilityStatusType.overTarget) {
      return {
        status: AutoOrderFacilityStatusType.overTarget,
        level: 'warning',
        message: `${inv.inventory.ConsumptionType === 'consumeFulls' ? 'Too Many Loaded' : 'Too Many Empties'}`,
      };
    } else if (inv.state.Status === AutoOrderFacilityStatusType.underPool) {
      return {
        status: AutoOrderFacilityStatusType.underPool,
        level: 'warning',
        message: 'Under Pool',
      };
    } else if (inv.state.Status === AutoOrderFacilityStatusType.overPool) {
      return {
        status: AutoOrderFacilityStatusType.overPool,
        level: 'warning',
        message: 'Over Pool',
      };
    }
    return {
      status: AutoOrderFacilityStatusType.ok,
      level: 'ok',
      message: 'OK',
    };
  }

  public getPoolStatus() {
    if (this.trailerCounts.PoolStatus === AutoOrderPoolStatus.underPool) {
      return {
        status: AutoOrderFacilityStatusType.underPool,
        level: 'warning',
        message: 'Under Pool',
      };
    } else if (this.trailerCounts.PoolStatus === AutoOrderPoolStatus.overPool) {
      return {
        status: AutoOrderFacilityStatusType.overPool,
        level: 'warning',
        message: 'Over Pool',
      };
    }
    return {
      status: AutoOrderFacilityStatusType.ok,
      level: 'ok',
      message: 'OK',
    };
  }

  public getPoolCount() {
    return this.getLoadedTarget() + this.getEmptyTarget();
  }
}

export class UsableFacility implements IUsableFacility {
  public readonly inventories: UsableInventory[] = [];

  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly refNum: string,
    public readonly type: AutoOrderFacilityType,
    public state: FacilityState,
    public readonly trailerInventories: TrailerInventory[],
    public readonly status: {
      isServiceCenter: boolean;
      status: AutoOrderFacilityStatusType;
      level: 'ok' | 'warning' | 'critical';
      message: string;
    },
    public readonly inputInfo: AutoOrderFacility,
    public readonly demand: number,
    public readonly issues: number,
    public readonly collections: number,
    public important: boolean,
    public readonly otpRisk: boolean,
    public readonly timestamp: string,
    public readonly serviceCenterOutput?: ServiceCenterOutput,
  ) {
    for (const state of Object.values(this.state.StateByInventoryID)) {
      if (state.TrailerType === 'dryVan') {
        state.TrailerType = 'dry_van';
      } else if (state.TrailerType === 'flatBed') {
        state.TrailerType = 'flat_bed';
      }
      this.state.StateByInventoryID[state.InventoryID] = state;
    }

    const cleanupCounts: Record<string, TrailerCounts> = {};
    for (const entry of Object.entries(this.state.TrailerCountsByType)) {
      const type = entry[0];
      const count = entry[1];
      if (type === 'dryVan') {
        cleanupCounts['dry_van'] = count;
      } else if (type === 'flatBed') {
        cleanupCounts['flat_bed'] = count;
      } else {
        cleanupCounts[type] = count;
      }
    }
    this.state.TrailerCountsByType = cleanupCounts;

    for (const trailerInventory of this.trailerInventories) {
      if (trailerInventory.TrailerType === 'dryVan') {
        trailerInventory.TrailerType = 'dry_van';
      } else if (trailerInventory.TrailerType === 'flatBed') {
        trailerInventory.TrailerType = 'flat_bed';
      }
    }

    const byType: Record<string, StateAndInventory[]> = {};
    for (const entry of Object.entries(this.state.TrailerCountsByType)) {
      const ti = this.trailerInventories.filter((value) => value.TrailerType === entry[0]) ?? [];
      for (const trailerInventory of ti) {
        if (!byType[trailerInventory.TrailerType]) {
          byType[trailerInventory.TrailerType] = [];
        }
        const state = this.state.StateByInventoryID[trailerInventory.ID];
        byType[trailerInventory.TrailerType].push({
          inventory: trailerInventory,
          state,
        });
      }
    }

    for (const entry of Object.entries(byType)) {
      const counts = this.state.TrailerCountsByType[entry[0]];
      this.inventories.push(new UsableInventory(entry[0], entry[1], counts, this));
    }
  }

  public getTotalTrailersAtFacility(): number {
    const counts = Object.values(this.state.TrailerCountsByType);
    return counts.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.Empty + currentValue.LoadedInbound + currentValue.LoadedOutbound,
      0,
    );
  }

  public getTotalTrailersAtFacilityByTrailerType(type: string) {
    const countByType = this.state.TrailerCountsByType[type];
    if (!countByType) {
      return 0;
    }
    return countByType.LoadedInbound + countByType.Empty + countByType.LoadedOutbound;
  }

  public totalTargetCount() {
    return this.inventories.reduce(
      (previousValue, currentValue) => previousValue + currentValue.getTotalTargetCount(),
      0,
    );
  }

  public compareStatusWithInventory(inv: UsableInventory) {
    const invStatus = inv.getInventoryStatus();
    if (invStatus.level !== AutoOrderFacilityStatusType.ok) {
      return invStatus;
    } else if (this.state.Status !== AutoOrderFacilityStatusType.ok) {
      return {
        ...this.status,
        isServiceCenter: this.type === AutoOrderFacilityType.serviceCenter,
      };
    } else {
      return {
        level: 'ok',
        message: 'OK',
        status: AutoOrderFacilityStatusType.ok,
        isServiceCenter: this.type === AutoOrderFacilityType.serviceCenter,
      };
    }
  }

  public getStatus() {
    for (const inventory of this.inventories) {
      if (inventory.getInventoryStatus().status !== AutoOrderFacilityStatusType.ok) {
        return inventory.getInventoryStatus();
      }
    }
    for (const inventory of this.inventories) {
      if (inventory.getPoolStatus().level !== 'ok') {
        return inventory.getPoolStatus();
      }
    }
    return this.status;
  }

  public getTotalCapacity() {
    return this.inputInfo?.TotalTrailerCapacity || 0;
  }
}

export interface NewServiceCenter {
  point: [number, number];
  costPerPallet?: number;
  name?: number;
  facilities?: UsableFacility[];
}

export interface LoadAndTruckStats {
  poolId: number;
  statTimeSeries: StatTimeSeries[];
}

export interface StatTimeSeries {
  forTime: string;
  readyLoadCount: number;
  waitingDriverCount: number;
}

export interface AlarmAndPriorityCustomerInfo {
  alarmTimeSeries: AlarmForecastAtTime[];
  priorityCustomerStates: PriorityFacilityState[];
}

export interface AlarmForecastAtTime {
  forDate: string;
  priorityFacilitiesCounts: AlarmForecastCountSet;
  nonPriorityFacilitiesCounts: AlarmForecastCountSet;
  totalCounts: AlarmForecastCountSet; // generated locally
}

export interface AlarmForecastCountSet {
  tpmCount: AlarmForecastCounts;
  retailerCount: AlarmForecastCounts;
  manufacturerCount: AlarmForecastCounts;
}

export interface AlarmForecastCounts {
  stockOutCount: number;
  criticalCount: number;
  overPoolCount: number;
  overTargetCount: number;
  underTargetCount: number;
  underPoolCount: number;
  needsDeliveryCount: number;
  overYardMaxCount: number;
  okCount: number;
}

export interface PriorityFacilityState {
  facilityId: string;
  facilityName: string;
  facilityExternalId: string;
  state: FacilityState;
  stateTimestamp: string;
}

export interface TrailerStateFacility {
  facilityID: string;
  facilityName: string;
  facilityReferenceNumber: string;
  count: number;
}

export interface TrailerStateCounts {
  trailerType: string;
  atPool: number;
  inTransit: number;
  needsMaintenance: number;
  overpoolAtCustomer: number;
  overpoolAtCustomerFacilities: Record<string, TrailerStateFacility>;
  overpoolAtServiceCenter: number;
  overpoolAtServiceCenterFacilities: Record<string, TrailerStateFacility>;
  offSite: number;
  unknown: number;
  totalCount: number;
}

export interface AutoOrderRecommendationCounts {
  createCount: number;
  cancelCount: number;
  rescheduleCount: number;
  nextSendTime: string;
}

export interface ServiceCenterActionDataAlarm {
  alarmType: string;
  alarmText: string;
  since?: string;
}

export interface ServiceCenterInterestedPartiesUsersVisited {
  relationType: string;
  facilityId: string;
  customerAccountName: string;
  customerAccountId: string;
  lastVisited: string;
  uniqueLastVisits: number;
}

export interface ServiceCenterActionData {
  id: string;
  name: string;
  externalId: string;
  trailerCountsByTrailerType: Record<string, ServiceCenterTrailerCounts>;
  behindOnLoading: boolean;
  behindOnEmpties: boolean;
  needsInbound: boolean;
  dwellMinutes: number | null;
  interestedPartiesUsersVisited: ServiceCenterInterestedPartiesUsersVisited[];
  alarms: ServiceCenterActionDataAlarm[];
}

export interface ServiceCenterTrailerCounts {
  loadedInboundTrailerCountData: SCInventoryTrailerCountData;
  loadedOutboundTrailerCountData: SCInventoryTrailerCountData;
  emptyTrailerCountData: SCInventoryTrailerCountData;
}

export interface SCInventoryTrailerCountData {
  count: number;
  target: number | null;
  showAsRed: boolean;
  trailers: TrailerIDNameLoad[];
}

export interface LoadPriorityDetail {
  isPriority: string;
  priorityType: string;
  timedEndsAt: string;
}

export interface AllLoadItem {
  loadId: string;
  referenceNumber: string;
  isReady: boolean;
  readyNote: string;
  readyCustomerNote: string;
  readyAt: string;
  readyBy: string;
  customerName: string;
  status: LohiLoadStatus;
  pickupStartsAt: string;
  pickupEndsAt: string;
  pickupExtremaStartsAt: string;
  pickupExtremaEndsAt: string;
  pickupFacilityId: string;
  pickupFacilityName: string;
  pickupFacilityRef: string;
  pickupEta: string;
  pickupArrivedAt: string;
  pickupCompletedAt: string;
  pickupLoadingType: LoadingType;
  pickupAppointmentNumber: string;
  dropoffStartsAt: string;
  dropoffEndsAt: string;
  dropoffExtremaStartsAt: string;
  dropoffExtremaEndsAt: string;
  dropoffFacilityId: string;
  dropoffFacilityName: string;
  dropoffFacilityRef: string;
  dropoffEta: string;
  dropoffArrivedAt: string;
  dropoffCompletedAt: string;
  dropoffLoadingType: LoadingType;
  dropoffAppointmentNumber: string;
  pastDue: boolean;
  hasEAPStop: boolean;
  eapFacilityId: string;
  eapFacilityName: string;
  hasTrailerAssigned: boolean;
  assignedTrailer: string;
  assignedTrailerInMaintenance: boolean;
  companyId: string;
  companyName: string;
  driverId: string;
  driverName: string;
  poolTimezone: string;
  poolName: string;
  priorityDetail?: LoadPriorityDetail;
  queuedForCancellation: boolean;
  wasAutodispatched: boolean | null;
  brokeredLoad: boolean;
  appointmentRequired: boolean;
  isRecentlyPrioritized: boolean;
}

export interface TrailerIDNameLoad {
  id: number;
  name: string;
  loadId: string | null;
  loadRefNumber: string | null;
}

export interface AllLoadsSavedView {
  id: string;
  name: string;
  view: {
    columnState: any;
    columnGroupState: any;
    filterState: any;
  };
}

export interface InColtButNotVstTrailer {
  assetId: number;
  assetName: string;
  referenceNumber: string;
  location: string;
  status: string;
}

export interface AutoOrderEmailHistoryLineItem {
  id: number;
  facilityId: string;
  facilityName: string;
  facilityRefNum: string;
  emailContent: string;
  emailAddress: string;
  emailSubject: string;
  userId: string;
  userEmail: string;
  userName: string;
  sentAt: string;
  didClickLink: boolean;
  countOfTrailersChanged: number;
}

export interface PivotInfo {
  pivotDays: PivotDayInfo[];
  needsUpdate: boolean;
  updatedAt: string;
}

export interface PivotDayInfo {
  date: string;
  facilities: PivotDayFacility[];
}

export interface PivotDayFacility {
  facilityId: string;
  facilityName: string;
  customerReferenceNumber: string;
  date: string;
  aStockFloorPallets: number;
  bStockFloorPallets: number;
  cStockFloorPallets: number;
  aInboundTrailers: number;
  bInboundTrailers: number;
  cOutboundTrailers: number;
  emptyTrailers: number;
  issuesToDoCount: number;
  aCollectionsCount: number;
  bCollectionsCount: number;
  updatedAt: string;
}

export interface PivotRecommendation {
  recId: number;
  loadId: string;
  loadReferenceNumber: string;
  fromFacilityId: string;
  fromFacilityName: string;
  fromCustomerReferenceNumber: string;
  toFacilityId: string;
  toFacilityName: string;
  toCustomerReferenceNumber: string;
  isInbound: boolean;
}

export interface OutOfMarketRecommendation {
  collectionOutOfMarketCount: number;
  issueOutOfMarketCount: number;
}

export interface PivotRecommendationResponse {
  recommendations: PivotRecommendation[];
  swapRecommendations: SwapRecommendation[];
  outOfMarket: OutOfMarketRecommendation;
}

export interface SwapRecommendation {
  stop1LoadId: string;
  stop1ReferenceNumber: string;
  stop1OldFacilityId: string;
  stop1NewFacilityId: string;
  stop1OldFacilityName: string;
  stop1NewFacilityName: string;
  stop1OldFacilityRefNumber?: string;
  stop1NewFacilityRefNumber?: string;
  load1OtherStopFacility: string;
  stop2LoadId: string;
  stop2ReferenceNumber: string;
  stop2OldFacilityId: string;
  stop2NewFacilityId: string;
  stop2OldFacilityName: string;
  stop2NewFacilityName: string;
  stop2OldFacilityRefNumber?: string;
  stop2NewFacilityRefNumber?: string;
  load2OtherStopFacility?: string;
  milesSaved: number;
}

export interface ServiceCenterPivotConfig {
  facilityId: string;
  facilityName: string;
  customerReferenceNumber: string;
  yardMax: number;
  floorMax: number;
  damageRate: number;
  inboundFunnel: number;
  outboundFunnel: number;
  maxPalletsRepairedPerDay: number;
  maxPalletsSortedPerDay: number;
  palletsPerCube: number;
  palletsPerTrailer: number;
}

export interface EmptyMileLane {
  pickupId: string;
  pickupName: string;
  pickupRefNumber: string;
  pickupAddress: string;
  pickupLngLat: LongLat;
  dropoffId: string;
  dropoffName: string;
  dropoffRefNumber: string;
  dropoffAddress: string;
  dropoffLngLat: LongLat;
  loads: number;
  volume: number;
  distance: number;
  totalEmptyMiles: number;
}

export interface EmptyMilePickupAggregate {
  pickupId: string;
  pickupName: string;
  pickupRefNumber: string;
  pickupAddress: string;
  pickupLngLat: LongLat;
  totalLoads: number;
  totalEmptyMiles: number;
  weeklyEmptyMiles: number;
}

export interface EmptyMileNetworkMapResponse {
  emptyMilesMapInfo: EmptyMileLane[];
  pickupAggregate: EmptyMilePickupAggregate[];
}

export interface DMXForecastResponse {
  forecast: DMXForecastSnapshot[];
  floorMax: number;
}

export interface DMXForecastSnapshot {
  Day: string;
  IssuesToDo: number;
  UnloadingB: number;
  AStockFloor: number;
  BStockFloor: number;
  CStockFloor: number;
  EmptyInYard: number;
  AInboundInYard: number;
  BInboundInYard: number;
  COutboundInYard: number;
  ACollectionsToDo: number;
  BCollectionsToDo: number;
  LoadedRatioAtTheDock: number;
  UnloadedRatioAtTheDock: number;
}
